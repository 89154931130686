import { Auth } from '../constants/auth.js';

enum Paths {
  ROOT = '/',
  ORDERS = '/orders/*',
  INTERNAL_TRANSFERS = '/internal-transfers/*',
  PRODUCTS = '/products',
  TERMINALS = '/terminals',
  USERS = '/users/*',
  RECIPES = '/recipes',
  INTERMEDIATES = '/intermediates',
  LOGIN = '/login',
}

export class AsphaltPortalAuth {
  /** URL pathname permission configuration */

  public static readonly urlPathnames: Paths[] = [
    Paths.ROOT,
    Paths.ORDERS,
    Paths.INTERNAL_TRANSFERS,
    Paths.PRODUCTS,
    Paths.TERMINALS,
    Paths.USERS,
    Paths.RECIPES,
    Paths.INTERMEDIATES,
    Paths.LOGIN,
  ];

  static canView(permissions: string[], key: Paths) {
    switch (key) {
      case Paths.ORDERS:
      case Paths.PRODUCTS:
      case Paths.INTERMEDIATES:
      case Paths.TERMINALS:
        return permissions.includes(Auth.BLENDSADMIN) || permissions.includes(Auth.ORDERSADMIN);
      case Paths.USERS:
      case Paths.INTERNAL_TRANSFERS:
      case Paths.RECIPES:
        return permissions.includes(Auth.BLENDSUSER);
      case Paths.LOGIN:
        return true;
      default:
        return false;
    }
  }

  static getAccess(permissions: string[], key: Paths) {
    const isBlendsUser = permissions.includes(Auth.BLENDSUSER) || permissions.includes(Auth.BLENDSADMIN);
    const isBlendsAdmin = permissions.includes(Auth.BLENDSADMIN);
    const isOrdersUser = permissions.includes(Auth.ORDERSUSER) || permissions.includes(Auth.ORDERSADMIN);
    const isOrdersAdmin = permissions.includes(Auth.ORDERSADMIN);
    const canView = this.canView(permissions, key);

    return {
      isBlendsUser,
      isBlendsAdmin,
      isOrdersUser,
      isOrdersAdmin,
      canView,
    };
  }

  static includesOA(permissions: string[]) {
    return permissions.includes(Auth.ORDERSADMIN);
  }

  static includesOU(permissions: string[]) {
    return permissions.includes(Auth.ORDERSUSER);
  }

  static includesBA(permissions: string[]) {
    return permissions.includes(Auth.BLENDSADMIN);
  }

  static includesBU(permissions: string[]) {
    return permissions.includes(Auth.BLENDSUSER);
  }
}
